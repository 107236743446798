import * as Yup from 'yup'

export const getUrl = (): string => {
  const url = process.env.NEXT_PUBLIC_RELAY_ENDPOINT
  if (url) {
    return url
  } else {
    throw new Error('Could not find NEXT_PUBLIC_RELAY_ENDPOINT')
  }
}
export const isDEV = process.env.NODE_ENV === 'development'

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
export const MIN_FILE_SIZE = 1024

export const yupImageUploadSchema = Yup.mixed()
  .test({
    name: 'fileFormat',
    message: 'Unsupported Format',
    test: (value) => (value && value.type ? SUPPORTED_FORMATS.includes(value.type) : true),
  })
  .test('fileSize', 'File is too small, must be greater than 1KB', (value) =>
    value && value.size ? value.size >= MIN_FILE_SIZE : true
  )
export const {
  // This write key is associated with https://segment.com/nextjs-example/sources/nextjs.
  NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
} = process.env

export const TRACKING_ENABLED = NEXT_PUBLIC_ANALYTICS_WRITE_KEY ? true : false

// nop
