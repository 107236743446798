import * as Sentry from '@sentry/node'
import { captureException as sentryCaptureException } from '@sentry/react'
import { RewriteFrames } from '@sentry/integrations'
import { isDEV } from '../constant'
const SENTRY_SERVER_ROOT = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR || ''

export const init = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = []
    if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            if (frame && frame.filename) {
              frame.filename = frame.filename.replace(SENTRY_SERVER_ROOT, 'app:///')
              frame.filename = frame.filename.replace('.next', '_next')
            }
            return frame
          },
        })
      )
    }

    Sentry.init({
      enabled: !isDEV,
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    })
  }
}

export const captureException = (error: string | Error, extra = {}): void => {
  if (isDEV) {
    console.error('Caught exception, logging to console. In production will be sent to sentry')
    console.error({ error, extra })
  } else {
    if (error instanceof Error) {
      sentryCaptureException(error, { extra })
    } else {
      sentryCaptureException(new Error(error), { extra })
    }
  }
}

export const captureRelayError = (error: any) => {
  // extras errors and adds them to sentry extra
  captureException(error, {
    errors: error.source && [...error.source.errors.map((i: any) => i.message)],
    variables: error.variables,
  })
}
